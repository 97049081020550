import { mapGetters, mapActions } from 'vuex';
import _Alerts from '../components/Alerts/index.vue';
import _svg from './svg.vue';
import _diffArray from '../../../helpers/_diffArray.js';
import scrollToTop from '../../../helpers/cabinetScroll';
import { SEGMENT_WITH_SWITCH_PHONE } from '../../../enums/segments';
import { AB_TEST_ID } from '../../../../constants/abTest';

const { get, set } = require('../../../helpers/cookie');

const LazyLimit = 5;
const EXPIRES = 604800;

export default {
  name: 'Advertisements',
  components: {
    Pagination: () => import('../../Common/Pagination/index.vue'),
    Tabs: () => import('./Tabs/index.vue'),
    Advertisement: () => import('./Advertisement/index.vue'),
    Filter_: () => import('./Filter/index.vue'),
    Actions: () => import('./Actions/index.vue'),
    Republication: () => import('./RepublicationSetting/index.vue'),
    SalePopup: () => import('./SalePopup/index.vue'),
    DeletePopup: () => import('./DeletePopup/index.vue'),
    MoveToArchivePopup: () => import('./MoveToArchivePopup/index.vue'),
    RepublicationPopup: () => import('./RepublicationPopup/index.vue'),
    SwitchPhone: () => import('./SwitchPhone/index.vue'),
    CallTrackingPopup: () => import('./CallTrackingPopup/index.vue'),
    VinPromo: () => import('./VinPromo/index.vue'),
    Alerts: _Alerts,
    'adv-svg': _svg,
    ThanksForVINPopup: () => import('./ThanksForVINPopup/index.vue'),
    QRCode: () => import('./QRCode/index.vue'),
    UpdateStatisticsPopup: () => import('./UpdateStatisticsPopup/index.vue'),
    UpdatedStatisticsPopup: () => import('./UpdatedStatisticsPopup/index.vue'),
  },
  data() {
    return {
      checked: false,
      checks: {},
      lazySize: LazyLimit,
      loadedAutos: [],
      reloadLazy: false,
      showAlertEmptyAdv: false,
      queryParams: {},
      id: 0,
    };
  },
  created() {
    this.setType(this.$route.meta.type);
    this.setPage(this.$route.query.page || 1);
    this.fetchABTests();
    this.fetch();
  },
  mounted() {
    this.setSize(get('ipp') || 20);
    this.queryParams = this.$route.query;
    const { id } = this.queryParams;
    if (id) {
      this.showPhoneManagePopup(id);
    }
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      page: 'cabinet/advertisements/page',
      size: 'cabinet/advertisements/size',
      advertisements: 'cabinet/advertisements/advertisements',
      popup: 'cabinet/advertisements/popups/popup',
      showLoader: 'cabinet/advertisements/showLoader',
      counters: 'cabinet/leftBar/counters',
      type: 'cabinet/advertisements/type',
      prefetchCount: 'cabinet/advertisements/prefetchCount',
      langId: 'lang/id',
      segment: 'cabinet/segment',
      getAdvCount: 'cabinet/advertisements/getAdvCount',
      advertisementsActive: 'cabinet/advertisements/advertisementsActive',
      getProSellerPageData: 'proAccount/getProSellerPageData',
      getABtestVariant: 'others/analytic/getABtestVariant',
    }),
    currentCount() {
      if (this.counters && this.counters[this.$route.name]) {
        return this.counters[this.$route.name].count;
      }
      return 0;
    },
    _lazySize() {
      return this.lazySize;
    },
    advertisements_() {
      const startSlice = (this.page - 1) * this.size;
      const endSlice = (this.page - 1) * this.size + this._lazySize;

      return this.advertisements.length ? this.advertisements.slice(startSlice, endSlice) : [];
    },
    lastPage() {
      return Math.ceil(this.advertisements.length / this.size);
    },
  },
  watch: {
    advertisements_(ids) {
      const diffIds = _diffArray(ids, this.loadedAutos);
      this.loadedAutos = ids;

      this
        .fetchAdvertisements({ ids: diffIds })
        .then(() => this.setLoaderValue(false));

      if (this.$route.query?.callTracking) {
        this.showPhoneManagePopup(ids.at(-1));
      }
    },
    getAdvCount(data) {
      this.showAlertEmptyAdv = Boolean(data);
    },
    checked(checked) {
      if (this.advertisements_.length) {
        this.advertisements_.map((id) => {
          const info = this.info_(id);
          // не відмічаємо тачки з автовикупу
          if (info && info.status === 'AUTO_BUY') {
            return;
          }
          this.checks[id] = checked;
        });
      }
    },
    page(page) {
      this.$router.push({ query: { page } });
      this.scrollToTop(280);
    },
    $route(to, from) {
      const currentPage = to.meta.type;
      const { langId } = this;
      this.setType(currentPage);

      if (to.name !== from.name) {
        this.resetFilter();
        this.$root.$emit('resetFilter');
        this.fetchFilterParam({ currentPage, langId });
        this.setPage(1);
      }

      if (!this.advertisements.length || (this.advertisements.length !== this.counters[this.$route.name].count)) {
        this.setLoaderValue(true);
        this.fetch();
      }
    },
    advertisementsActive(autoArr) {
      this.showPopups(autoArr);
    },
  },
  methods: {
    ...mapActions({
      fetch: 'cabinet/advertisements/fetch',
      fetchAdvertisements: 'cabinet/advertisements/advertisement/fetch',
      setPage: 'cabinet/advertisements/setPage',
      setType: 'cabinet/advertisements/setType',
      setLoaderValue: 'cabinet/advertisements/setLoaderValue',
      resetFilter: 'cabinet/advertisements/filter/reset',
      setSize: 'cabinet/advertisements/setSize',
      fetchFilterParam: 'cabinet/advertisements/filter/fetchFilterParam',
      openPopup: 'cabinet/advertisements/popups/open',
      fetchABtestVariants: 'others/analytic/fetchABtestVariants',
    }),
    checkLazy(advertisementId) {
      // eslint-disable-next-line max-len
      const adsRemainder = this.advertisements_.length - this.advertisements_.indexOf(advertisementId);
      if (adsRemainder <= 1) {
        this.lazyAddAdv();
      }
    },
    lazyAddAdv() {
      if (this.lazySize + LazyLimit <= this.size) {
        this.lazySize += LazyLimit;
      }
    },
    search() {
      this.fetch();
    },
    showPhoneManagePopup(id) {
      if (['c2c'].includes(this.segment)) {
        this.openPopup({ name: 'CallTrackingPopup', id });
      } else if ((SEGMENT_WITH_SWITCH_PHONE.includes(this.segment) || this.getProSellerPageData?.active)) {
        this.openPopup({ name: 'SwitchPhone', id });
      }
    },
    scrollToTop,
    onChangeLimit(limit) {
      this.reloadLazy = !this.reloadLazy;
      this.reloadMainVariable();
      this.setSize(limit);
      set('ipp', limit, { expires: EXPIRES });
      if (!this.advertisements_.length) {
        this.setPage(this.lastPage);
      }
    },
    setPageReloadVariable(page) {
      this.reloadMainVariable();
      this.setPage(page);
    },
    reloadMainVariable() {
      this.loadedAutos = [];
      this.lazySize = LazyLimit;
    },
    showPopups(autoArr) {
      const { showPopup } = this.$route.query;
      const autoId = Number(this.$route.query.autoId);

      if (showPopup && autoArr.includes(autoId)) {
        if (this.loadedAutos.includes(autoId)) {
          this.openPopup({ name: `${showPopup}Popup`, id: autoId });
        } else {
          this
            .fetchAdvertisements({ ids: [autoId] })
            .then(() => {
              this.openPopup({ name: `${showPopup}Popup`, id: autoId });
            });
        }
      }
    },
    fetchABTests() {
      if (!this.getABtestVariant(AB_TEST_ID.FAST_PAY_BTN)) {
        this.fetchABtestVariants({ testName: AB_TEST_ID.FAST_PAY_BTN });
      }
    },
  },
  i18n: {
    messages: {
      uk: {
        'Мои объявления': 'Мої оголошення',
        'Вы можете': 'Ви можете',
        'добавить новое': 'додати нове',
        'Сейчас у Вас нет опубликованных объявлений': 'Зараз у Вас немає опублікованих оголошень',
        'Сейчас у Вас нет архивных объявлений': 'Зараз у Вас немає архівних оголошень',
        'Сейчас у Вас нет объявлений, ожидающих оплаты': 'Зараз у Вас немає оголошень, які очікують оплати',
        'Сейчас у Вас нет объявлений, находящихся в режиме правок': 'Зараз у Вас немає оголошень, які перебувають в режимі правок',
        'У вас нет объявлений с указанными параметрами': 'У вас немає пропозицій з зазначеними параметрами',
      },
      ru: {
        'Мои объявления': 'Мои объявления',
        'Вы можете': 'Вы можете',
        'добавить новое': 'добавить новое',
        'Сейчас у Вас нет опубликованных объявлений': 'Сейчас у Вас нет опубликованных объявлений',
        'Сейчас у Вас нет архивных объявлений': 'Сейчас у Вас нет архивных объявлений',
        'Сейчас у Вас нет объявлений, ожидающих оплаты': 'Сейчас у Вас нет объявлений, ожидающих оплаты',
        'Сейчас у Вас нет объявлений, находящихся в режиме правок': 'Сейчас у Вас нет объявлений, находящихся в режиме правок',
        'У вас нет объявлений с указанными параметрами': 'У вас нет объявлений с указанными параметрами',
      },
    },
  },
};
